import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import ExternalLink from '../styled/ExternalLink'

const Container = styled.div`
  margin-top: ${props => props.theme.margin.top};
  margin-left: ${props => props.theme.margin.left};
`

interface Props {
  location: Location
}

// TODO: don't allow this page to be indexed

const NotFoundPage = (props: Props) => {
  const { location } = props
  return (
    <Layout>
      <SEO
        description="Sorry, your browser is not supported by this site"
        disableIndexing
        pathname={location.pathname}
        title="Unsupported browser"
      />

      <Container>
        <h1>Sorry, your browser is not supported by this site</h1>
        Please consider{' '}
        <ExternalLink
          href="http://outdatedbrowser.com/en"
          rel="nofollow noopener"
        >
          updating your browser
        </ExternalLink>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
